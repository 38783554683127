import React from 'react'
import loadable from '@loadable/component'
import PageWrapper from '../components/PageWrapper'
import MetaData from '../components/MetaData'
import { useSelector } from 'react-redux'
import { Spinner } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import setLanguage from '../helpers/languageConfig'

const Hero = loadable(() => import('../sections/landing1/Hero'))
const Feature = loadable(() => import('../sections/landing1/Feature'))
const FeeSection = loadable(() => import('../sections/landing1/FeeSection'))
const PortfolioMgt = loadable(() => import('../sections/landing1/PortfolioMgt'))
const GetStarted = loadable(() => import('../sections/landing1/GetStarted'))
const LoggedInHero = loadable(() => import('../sections/landing1/loggedInHero'))
const LoggedInPlans = loadable(() =>
  import('../sections/landing1/loggedInPlans')
)
const LatestNews = loadable(() => import('../sections/news/LatestNews'))

const IndexPage = props => {
  const { t, ready } = useTranslation(['landing', 'tables'], { useSuspense: false })
  const lang = setLanguage();
  const userSettings = useSelector(state => state.user.user_settings)
  const userSessionExpired = useSelector(state => state.user.session_expired)
  const userDarkModeSelector = useSelector(state => state.user.darkMode)
  const userDarkMode = userSessionExpired
    ? userDarkModeSelector
    : typeof userSettings !== 'undefined'
    ? userSettings.dark_mode
    : false

  const customFeeSection = {
    leftCol: '3',
    rightCol: '8',
    emailInput: false,
    title: ready && t('makeMore'),
    firstP: ready && t('weKeep'),
    titleColor: userDarkMode ? '#FAFAFA' : '#003D8B'
  }

  if (userSessionExpired === true) {
    return ready && (
      <>
        <MetaData page="default" />
        <PageWrapper location={props.location} headerDark footerDark>
          <Hero />
          <Feature />
          <FeeSection config={customFeeSection} lang={lang} />
          <PortfolioMgt lang={lang} />
          <GetStarted lang={lang} />
        </PageWrapper>
      </>
    )
  } else if (userSessionExpired === false) {
    return ready && (
      <>
        <MetaData page="default" />
        <PageWrapper location={props.location} headerDark footerDark>
          <LoggedInHero />
          <LoggedInPlans />
          <LatestNews />
        </PageWrapper>
      </>
    )
  } else
    return ready && (
      <div style={{ alignItems: 'center' }}>
        <Spinner variant={'dark'} animation="grow" />
      </div>
    )
}
export default IndexPage
